@import "../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css" layer(bootstrap-reboot);
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/primeng.min.css' layer(primeng);
@import "../node_modules/primeng/resources/themes/md-light-indigo/theme.css";
@layer bootstrap-reboot, primeng;
body,
html {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

* {
  font-family: "Source Sans Pro", sans-serif;
  list-style: none;
  text-decoration: none !important;
  color: #7d7e82;
  letter-spacing: 0.5 px;
}

.filter-input {
  width: 100%;
  font-size: 14px;
}

input::-webkit-input-placeholder {
  color: rgb(173, 173, 173);
}

input[type=checkbox] {
  accent-color: orange;
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
}

button:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #ffdba8 !important;
  border-color: #f7a631 !important;
}

*:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: inset 0 0 0 0.2rem #ffdba8 !important;
  border-color: #f7a631 !important;
}

mat-dialog-container:focus {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-input-element:focus,
.mat-mdc-select,
.mat-tab-label,
.input-search {
  box-shadow: none !important;
}

input:-webkit-autofill {
  background-color: #fff !important;
}

.h2-header {
  display: flex;
  align-items: center;
}
.h2-header mat-icon {
  background: #f7a631;
  color: #fff;
  height: 36px;
  width: 42px;
  line-height: 30px;
  font-size: 30px;
  padding: 3px 6px 0 6px;
  box-shadow: inset 0 -9px 9px -9px #bf6409;
  border-radius: 3px 0 0 0;
}
.h2-header .back-arrow {
  background: #fff;
  color: #f7a631;
  box-shadow: inset 0 -9px 9px -9px #eaeaea;
}
.h2-header a {
  height: 36px;
}
.h2-header button {
  padding: 0px;
  border: none;
  outline: none;
  height: 36px;
}
.h2-header h2 {
  background: #fff;
  margin-bottom: 0;
  padding: 3px 20px;
  font-size: 20px;
  box-shadow: inset 0 -9px 9px -9px #eaeaea;
  border-radius: 0 3px 0 0;
}

.section-pad-clicked {
  padding: 92px 30px 45px 315px;
  background: #f0f0f7;
  width: 100%;
  min-height: 100vh;
}

header .mat-form-field-infix {
  border-top-width: 0px !important;
}

.no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.mat-mdc-dialog-container {
  overflow: hidden;
}

.modal-doc-cont .mat-form-field-infix {
  width: 375px !important;
}
.modal-doc-cont .mat-form-field-infix .mat-mdc-input-element {
  caret-color: #f7a631 !important;
}

.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: #f7a631 !important;
}

.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background: #f7a631;
}

.cdk-overlay-container {
  z-index: 8 !important;
}

.modal-doc-cont-res table.mat-mdc-table {
  width: 380px;
  box-shadow: none;
}
.modal-doc-cont-res table.mat-mdc-table th.mat-mdc-header-cell {
  background: #f5f6fa;
  font-size: 16px;
  font-weight: 700;
}
.modal-doc-cont-res table.mat-mdc-table th.mat-mdc-header-cell,
.modal-doc-cont-res table.mat-mdc-table td.mat-mdc-cell {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.mtmb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.table {
  margin-bottom: 0;
}
.table td {
  cursor: pointer;
}

.table-cont {
  background: #fff;
  padding: 20px;
}

.main-table > .ui-table {
  margin-top: 15px;
}

.mat-ink-bar {
  background: #f7a631 !important;
}

.mat-tab-label {
  min-width: 130px !important;
}

.mat-tab-label-content {
  font-size: 18px !important;
  font-weight: 300;
}

.mat-tab-label-active .mat-tab-label-content {
  color: #f7a631 !important;
  font-weight: 400;
}

.mat-mdc-tab-body {
  margin-top: 20px !important;
}

.p-datatable table {
  table-layout: auto !important;
}

.p-datatable thead,
.p-treetable thead {
  font-size: 14px;
  background: #f5f6fa;
  letter-spacing: 0.5px;
}
.p-datatable th,
.p-treetable th {
  font-size: 15px;
  font-weight: 700;
  color: #303030;
}
.p-datatable td,
.p-treetable td {
  border-top: none !important;
  border-bottom: 1px solid #dee2e6;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: anywhere;
}
.p-datatable td a,
.p-treetable td a {
  color: #7d7e82;
}
.p-datatable tbody tr,
.p-treetable tbody tr {
  font-size: 14px;
}
.p-datatable tbody tr .view-icon,
.p-datatable tbody tr .download-icon,
.p-treetable tbody tr .view-icon,
.p-treetable tbody tr .download-icon {
  text-align: center;
}
.p-datatable tbody tr .mat-checkbox-inner-container,
.p-treetable tbody tr .mat-checkbox-inner-container {
  width: 40px;
  height: 40px;
  margin-top: 4px;
}
.p-datatable .p-checkbox .p-checkbox-box,
.p-treetable .p-checkbox .p-checkbox-box {
  border-radius: 0 !important;
}
.p-datatable .p-checkbox .p-checkbox-box.p-highlight,
.p-treetable .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f7a631 !important;
  background-color: #f7a631 !important;
}
.p-datatable .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-treetable .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #f7a631 !important;
}
.p-datatable .p-link:focus,
.p-treetable .p-link:focus {
  box-shadow: 0 0 0 0.2rem #ffdba8 !important;
}
.p-datatable .p-treetable-toggler,
.p-treetable .p-treetable-toggler {
  width: 1rem !important;
  height: 1rem !important;
  margin-right: 0 !important;
}

.p-inputtext:focus,
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #ffdba8 !important;
  border-color: #f7a631 !important;
}

.p-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #ffdba8 !important;
  border-color: #f7a631 !important;
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-calendar .p-inputtext {
  font-size: 14px;
}

.p-calendar .p-datepicker {
  min-width: 135% !important;
}

p-accordion p-header {
  font-size: 14px;
}
p-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.457rem 1rem;
}
p-accordion .p-accordion .p-accordion-header .p-accordion-header-link:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}
p-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #dbdbdb;
  border-color: #dbdbdb;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
p-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link:hover {
  background: #dcdcdc;
  border-color: #b3b3b3;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f7a631 !important;
}

.picker-dialog-bg {
  background: rgba(0, 0, 0, 0.4) !important;
  opacity: 0.6 !important;
}

.modal-rename-cont .mat-form-field-infix {
  width: 375px !important;
}

.table-td {
  cursor: pointer;
}

@media (max-width: 1100px) {
  section {
    padding: 20px !important;
    background: #fff !important;
  }
  .h2-header {
    background: #31404d;
  }
  .h2-header mat-icon {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #f7a631;
  }
  .h2-header h2 {
    box-shadow: none;
    border-radius: 0;
    background: #31404d;
    color: #fff;
  }
  span .button-span {
    display: none;
  }
  .mat-mdc-tab-body {
    margin-top: 0 !important;
  }
  .mat-mdc-tab-header {
    border-bottom: none !important;
  }
  .mat-tab-label-container {
    overflow-x: scroll !important;
  }
  .mat-tab-label-container::-webkit-scrollbar {
    display: none;
  }
  .table-cont {
    padding: 0;
  }
  tr:nth-child(even) {
    background: #f0f0f7 !important;
  }
  .modal-doc-cont .mat-form-field-infix {
    width: 300px !important;
  }
}
.progress-loading-folder {
  display: inline-block !important;
  vertical-align: middle !important;
  margin-right: 10px !important;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(148, 148, 148);
}