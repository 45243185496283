@use '@angular/material' as mat;

@include mat.core();

// $plenion-theme-primary: mat-palette($mat-orange);
// $plenion-theme-accent: mat-palette($mat-orange, A100, A100, A400);
// $plenion-theme-warn: mat-palette($mat-orange);
$plenion-theme-primary: mat.define-palette(mat.$orange-palette, 500);
$plenion-theme-accent: mat.define-palette(mat.$orange-palette, A100, A100, A400);
$plenion-theme-warn: mat.define-palette(mat.$orange-palette);

$plenion-theme-theme: mat.define-light-theme((
	color: (
		primary: $plenion-theme-primary,
		accent: $plenion-theme-accent,
		warn: $plenion-theme-warn,
	),
	density: -1
));

.cdk-global-scrollblock {
	position: static;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
	background: rgba(255, 239, 205, 0.6) !important;
	color: #ffffff;
}

// TOAST

.p-toast .p-toast-message.p-toast-message-info {
	background: #efa62f !important;

	&:hover {
		cursor: pointer;
		background: #e4892d !important;
	}
}

span.p-toast-icon-close-icon {
	color: white !important;
}

@media (max-width: 576px) {
	// P-TOAST
	.p-toast {
		margin-top: 0px !important;
		width: 18rem !important;
	}

	.p-toast-top-right {
		right: 15px !important;
	}
}

// SWITCH

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: #efa62f !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
	box-shadow: 0 0 0 0.2rem #ffdba8 !important;
}

// CHECKBOX

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	background: #ffad3a !important;
}

.p-dropdown {
	width: 100%;
}

.p-checkbox .p-checkbox-box.p-highlight {
	background: #ffad3a !important;
	border-color: #efa62f !important;
}

.p-checkbox-label{
	margin-bottom: 0px;
}

// DROPDOWNS

.p-multiselect-item.p-highlight {
	background-color: #ffffff !important;
}

// MULTISELECT

.p-selectbutton .p-button.p-highlight {
	border-color: #efa62f !important;
	background-color: #ffdba8 !important;
}

.p-selectbutton .p-button {
	background: white !important;
}

@include mat.all-component-themes($plenion-theme-theme);

// @include angular-material-theme($plenion-theme-theme);
